<template>
  <div class="">
    <footer class="cs-footer" v-if="endOfconstructor">
      <a href="#constructor">
        <div class="cs-footer__to-top">
          <img lazy="loading" src="../../../assets/img/ico/ico-to-top.svg" alt="" />
        </div>
      </a>

      <p>
        <strong>Has llegado al final (¡de momento! 😉 )</strong> <br />
        Consulta a menudo esta pantalla, irán apareciendo nuevas secciones para ayudarte.
      </p>
    </footer>
    <footer class="cs-footer-more" v-else>
      <a href="#constructor">
        <div class="cs-footer-more__to-top">
          <img lazy="loading" src="../../../assets/img/ico/ico-to-top.svg" alt="" />
        </div>
      </a>

      <div class="cs-footer-more__cta">
        <div class="cs-footer-more__title">Has llegado al final</div>
        <div class="cs-footer-more__description">
          Nuevas secciones irán apareciendo cada semana pero,
          si no puedes esperar...
        </div>
        <div class="cs-footer__actions">
          <button class="button button--primary button--lg button--ico" @click="verMas">
            <i class="uil uil-arrow-down"></i>
            Muéstrame más sugerencias
          </button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "ExploreFooter",
  props: {
    endOfconstructor: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    verMas() {
      this.$emit("verMas");
    },
  },
};
</script>

<style></style>
